import React from "react";
import { Link, NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "../images/easy-logo.svg";

const Header = () => {
  return (
    <header className="mb-4">
      <nav className="navbar navbar-light justify-content-center pt-3 pb-3">
        <Link to="/" className="navbar-brand">
          <Logo />
        </Link>
      </nav>
      <div className="nav-subnav bg-white shadow-sm">
        <nav className="nav justify-content-center">
          <NavLink to={"/calculator"} className={"nav-link"}>
            Calculator
          </NavLink>
          <NavLink to={"/recipes"} className={"nav-link"}>
            Recipes
          </NavLink>
          <NavLink to={"/guides"} className={"nav-link"}>
            Guides
          </NavLink>
        </nav>
      </div>
    </header>
  );
};

export default React.memo(Header);
