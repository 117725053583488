import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Loader from "./Loader";

export default function Layout() {
  return (
    <>
      <Header />
      <React.Suspense
        fallback={
          <div className="container text-center">
            <Loader />
          </div>
        }
      >
        <Outlet />
      </React.Suspense>
      <Footer />
    </>
  );
}
