import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Layout from "./components/Layout";

// These will be imported on demand
const Home = React.lazy(() => import("./components/routes/Home"));
const AtTemp = React.lazy(() => import("./components/routes/AtTemp"));
const Recipes = React.lazy(() => import("./components/routes/Recipes"));
const Recipe = React.lazy(() => import("./components/routes/Recipe"));
const Guides = React.lazy(() => import("./components/routes/Guides"));
const Guide = React.lazy(() => import("./components/routes/Guide"));
const Calculator = React.lazy(() => import("./components/routes/Calculator"));
const FourOhFour = React.lazy(() => import("./components/routes/404"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index={true} Component={Home} />
      <Route path="/at/:temp" Component={AtTemp} />
      <Route path="/calculator" Component={Calculator} />
      <Route path="/recipes" Component={Recipes} />
      <Route path="/recipes/:id" Component={Recipe} />
      <Route path="/guides" Component={Guides} />
      <Route path="/guides/:id" Component={Guide} />
      <Route path="*" Component={FourOhFour} />
    </Route>,
  ),
);

function App() {
  return (
    <div className="App">
      <React.Suspense fallback={null}>
        <RouterProvider router={router} />
      </React.Suspense>
    </div>
  );
}

export default App;
