const Footer = () => {
  return (
    <div className="footer">
      <div className="container text-center">
        <p>
          <small>Made in the USA</small>
        </p>
      </div>
    </div>
  );
};

export default Footer;
